import React from 'react';
import { DateTime } from 'luxon';

import Badge from 'components/Badge/Badge';
import Countdown from 'components/Countdown/Countdown';

import { ReactComponent as TvIcon } from './tv.svg';

import formatNumber from 'lib/formatNumber';

import './Relevant.css';

function Relevant({
  className = ''
}) {
  const currentTime = DateTime.local();
  const data = [
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Лото-Экспресс',
      description: 'Розыгрыши 5 раз в неделю',
      fgImg: '/assets/biglogos/LE.svg',
      bgImg: '/assets/relevant/LE.svg',
      bgColor: 'F63225',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 4 числа из 20<br> в каждом поле',
      description: 'Розыгрыши 4 раза в день',
      fgImg: '/assets/biglogos/4x20.svg',
      bgImg: '/assets/relevant/4x20.svg',
      bgColor: 'FE6200',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Русское лото',
      description: 'Найди счастливый билет.<br>Тираж раз в неделю на НТВ',
      fgImg: '/assets/biglogos/5x30.svg',
      bgImg: '/assets/relevant/5x30.svg',
      bgColor: 'F37E1A',
      superPrize: '50 домов в январе',
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: true,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Золотая подкова',
      description: 'Розыгрыш раз в неделю на НТВ',
      fgImg: '/assets/biglogos/zopo.svg',
      bgImg: '/assets/relevant/zopo.svg',
      bgColor: 'D37229',
      superPrize: '50 домов в январе',
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: true,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 6 чисел из 45',
      description: 'Самые крупные призы из числовых лотерей. Розыгрыши 2 раза в день',
      fgImg: '/assets/biglogos/6x45.svg',
      bgImg: '/assets/relevant/6x45.svg',
      bgColor: 'DB8300',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Собери фигуру на поле',
      description: 'Один билет может выиграть три раза.<br>Розыгрыши 5 раз в неделю',
      fgImg: '/assets/biglogos/Bingo75.svg',
      bgImg: '/assets/relevant/Bingo75.svg',
      bgColor: '69AB00',
      superPrize: 900000000,
      prizes: [
        15000,
      ],
      from: 100,
      tv: true,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 5 чисел из 36',
      description: 'Два суперприза в одной лотерее.<br>Розыгрыши каждые полчаса',
      fgImg: '/assets/biglogos/5x36.svg',
      bgImg: '/assets/relevant/5x36.svg',
      bgColor: '40823A',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 6 чисел из 36',
      description: 'Выиграть можно, угадав 2 числа.<br>Розыгрыш раз в неделю на НТВ',
      fgImg: '/assets/biglogos/6x36.svg',
      bgImg: '/assets/relevant/6x36.svg',
      bgColor: '108A1E',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: true,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадать от 1 до 10 из 80',
      description: 'Розыгрыши каждые 15 минут в два тура',
      fgImg: '/assets/biglogos/Keno.svg',
      bgImg: '/assets/relevant/Keno.svg',
      bgColor: '00813D',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 9 карт',
      description: 'Розыгрыши 3 раза в день',
      fgImg: '/assets/biglogos/Joker-white.svg',
      bgImg: '/assets/relevant/Joker.svg',
      bgColor: '00694A',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай числа в двух полях',
      description: 'Розыгрыши каждые 15 минут',
      fgImg: '/assets/biglogos/Rapido2-white.svg',
      bgImg: '/assets/relevant/Rapido2.svg',
      bgColor: '047C6E',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай по 2 числа в двух полях',
      description: 'Самый большой шанс выиграть суперприз.<br>Розыгрыши каждые 15 минут',
      fgImg: '/assets/biglogos/Duel.svg',
      bgImg: '/assets/relevant/Duel.svg',
      bgColor: '009B8B',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай всё или ничего и выиграй суперприз',
      description: 'Розыгрыши каждые 30 минут',
      fgImg: '/assets/biglogos/12x24.svg',
      bgImg: '/assets/relevant/12x24.svg',
      bgColor: '0782D6',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Жилищная лотерея',
      description: 'Выигрывай квартиры, машины, дома.<br>Тираж раз в неделю на НТВ',
      fgImg: '/assets/biglogos/JL.svg',
      bgImg: '/assets/relevant/JL.svg',
      bgColor: '08A1E1',
      superPrize: '50 домов в январе',
      prizes: [
        'Загородный дом',
      ],
      from: 100,
      tv: true,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 7 чисел из 49',
      description: 'Выигрывает каждый четвёртый билет.<br>Розыгрыши 5 раз в день',
      fgImg: '/assets/biglogos/7x49-white.svg',
      bgImg: '/assets/relevant/7x49.svg',
      bgColor: '8F15E9',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай 1, 2 или 3 числа',
      description: '8 способов игры.<br>Розыгрыши каждые 15 минут',
      fgImg: '/assets/biglogos/Top3-white.svg',
      bgImg: '/assets/relevant/Top3.svg',
      bgColor: '6F2ED0',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Выбери дату и знак зодиака',
      description: 'Выиграть можно, угадав одно число.<br>Розыгрыши 3 раза в день',
      fgImg: '/assets/biglogos/Zodiac-white.svg',
      bgImg: '/assets/relevant/Zodiac.svg',
      bgColor: '0023B8',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 10, minutes: 21, seconds: 12 }),
      header: 'Угадай числа в двух полях',
      description: 'Розыгрыши каждые 15 минут',
      fgImg: '/assets/biglogos/Rapido-white.svg',
      bgImg: '/assets/relevant/Rapido.svg',
      bgColor: 'C1069E',
      superPrize: 900000000,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 100,
      tv: false,
      href: '#',
    },
  ];

  return (
    <div
      className={`relevant ${className}`}
    >
      <h2 className="header relevant__header">
        Актуально
      </h2>
      <div className="relevant__content scroll-container scroll-container--big-padding">
        {data.map((item, idx) => (
          <a
            className="relevant__item"
            href={item.href}
            key={idx}
            style={{
              backgroundImage: `url(.${item.bgImg})`,
              backgroundColor: `#${item.bgColor}`,
            }}
          >
            {item.tv ? (
              <TvIcon
                className="relevant__item-tv"
              />
            ) : null}
            <div className="relevant__item-sub-header">
              <Countdown whenTime={item.whenDraw} prefix="Через" />
            </div>
            {item.header ? (
              <div className="relevant__item-header" dangerouslySetInnerHTML={{ __html: item.header }} />
            ) : null}
            {item.description ? (
              <div className="relevant__item-description" dangerouslySetInnerHTML={{ __html: item.description }} />
            ) : null}
            <img
              className="relevant__item-img"
              src={`.${item.fgImg}`}
            />
            <div className="relevant__item-bottom">
              <div className="relevant__item-superprize">
                <div className="relevant__item-small-header">
                  Суперприз
                </div>
                <Badge className="relevant__item-badge">
                  {!isNaN(item.superPrize) ? `${formatNumber(item.superPrize)} ₽` : item.superPrize}
                </Badge>
              </div>
              <div className="relevant__item-prizes">
                <div className="relevant__item-small-header">
                  Призы
                </div>
                <Badge
                  className="relevant__item-badge"
                  small
                  values={item.prizes.map((el) => !isNaN(el) ? `${formatNumber(el)} ₽` : el)}
                />
              </div>
            </div>
            <div className="relevant__item-from">
              от {formatNumber(item.from)} ₽
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Relevant;
