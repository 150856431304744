import React from 'react';

import './Interesting.css';

function Interesting({
  className = ''
}) {
  const data = {
    whereToBuyHref: '',
    momentLotteriesHref: '',
  };

  return (
    <div
      className={`interesting ${className}`}
    >
      <h2 className="header interesting__header">
        Интересное
      </h2>
      <div className="interesting__content">
        <a
          className="interesting__item interesting__item--one-click"
          href={data.whereToBuyHref}
        >
          <img
            className="interesting__item-bg-img"
            src="./assets/interesting/1-bg.png"
          />
          <div className="interesting__item-header">
            Билеты<br />
            в&nbsp;один клик
          </div>
          <img
            className="interesting__item-fg-img"
            src="./assets/interesting/1-fg.png"
          />
          <div className="interesting__item-button">
            Купить билеты
          </div>
        </a>
        <a
          className="interesting__item interesting__item--moment-lotteries"
          href={data.momentLotteriesHref}
        >
          <img
            className="interesting__item-fg-img"
            src="./assets/interesting/2-fg.png"
          />
          <div className="interesting__item-header">
            Моментальные<br />
            лотереи
          </div>
          <div className="interesting__item-button">
            Где купить билеты?
          </div>
        </a>
      </div>
    </div>
  );
}

export default Interesting;
