import React from 'react';

import './AllLotteries.css';

function AllLotteries({
  className = ''
}) {
  const data = [
    {
      img: '/assets/logos/5x30.svg',
      title: 'Русское лото',
      href: '#'
    },
    {
      img: '/assets/logos/JL.svg',
      title: 'Жилищная лотерея',
      href: '#'
    },
    {
      img: '/assets/logos/zopo.svg',
      title: 'Золотая подкова',
      href: '#'
    },
    {
      img: '/assets/logos/4x20.svg',
      title: 'Спортлото «4 из 20»',
      href: '#'
    },
    {
      img: '/assets/logos/5x36.svg',
      title: 'Спортлото «5 из 36»',
      href: '#'
    },
    {
      img: '/assets/logos/7x49.svg',
      title: 'Спортлото «7 из 49»',
      href: '#'
    },
    {
      img: '/assets/logos/Bingo75.svg',
      title: 'Бинго-75',
      href: '#'
    },
    {
      img: '/assets/logos/LE.svg',
      title: 'Лото-Экспресс',
      href: '#'
    },
    {
      img: '/assets/logos/Keno.svg',
      title: 'КЕНО-Спортлото',
      href: '#'
    },
    {
      img: '/assets/logos/Rapido.svg',
      title: 'Рапидо',
      href: '#'
    },
    {
      img: '/assets/logos/12x24.svg',
      title: '12/24',
      href: '#'
    },
    {
      img: '/assets/logos/Duel.svg',
      title: 'Дуэль',
      href: '#'
    },
    {
      img: '/assets/logos/Top3.svg',
      title: 'Топ-3',
      href: '#'
    },
    {
      img: '/assets/logos/Rapido2.svg',
      title: 'Рапидо 2.0',
      href: '#'
    },
    {
      img: '/assets/logos/6x36.svg',
      title: '6 из 36',
      href: '#'
    },
    {
      img: '/assets/logos/Joker.svg',
      title: 'Джокер',
      href: '#'
    },
    {
      img: '/assets/logos/Zodiac.svg',
      title: 'Зодиак',
      href: '#'
    },
    {
      img: '/assets/logos/6x45.svg',
      title: 'Спортлото «6 из 45»',
      href: '#'
    },
  ];

  return (
    <div
      className={`all-lotteries ${className}`}
    >
      <h2 className="header all-lotteries__header">
        Все лотереи
      </h2>
      <div className="all-lotteries__content">
        {data.map((item, idx) => (
          <a
            className="all-lotteries__item"
            href={item.href}
            key={idx}
          >
            <div className="all-lotteries__item-wrap">
              <img
                className="all-lotteries__item-img"
                src={`.${item.img}`}
              />
              <div className="all-lotteries__item-title">
                {item.title}
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default AllLotteries;
