import React from 'react';

import { ReactComponent as FirstFgIcon } from './1-fg.svg';
import { ReactComponent as SecondFgIcon } from './2-fg.svg';

import formatNumber from 'lib/formatNumber';

import './ForNewcomers.css';

function ForNewcomers({
  className = ''
}) {
  const data = [
    {
      bgImg: '/assets/newcomers/1-bg.png',
      fgCards: [
        '/assets/newcomers/Rapido.svg',
        '/assets/newcomers/Matchball.svg',
        '/assets/newcomers/Rapido.svg',
        '/assets/newcomers/Matchball.svg',
        '/assets/newcomers/Rapido.svg',
      ],
      bgColor: '6622D3',
      header: '×5',
      description: 'Больше билетов — больше шанс победить',
      from: 500,
      href: '#',
    },
    {
      bgImg: '/assets/newcomers/2-bg.png',
      fgCards: [
        '/assets/newcomers/Rapido.svg',
        '/assets/newcomers/Matchball.svg',
        '/assets/newcomers/Rapido.svg',
        '/assets/newcomers/Matchball.svg',
      ],
      bgColor: '225FD3',
      header: '×4',
      description: 'Больше билетов — больше шанс победить',
      from: 500,
      href: '#',
    },
  ];

  return (
    <div
      className={`for-newcomers ${className}`}
    >
      <h2 className="header for-newcomers__header">
        Подойдёт новичкам
      </h2>
      <div className="for-newcomers__content scroll-container">
        {data.map((item, idx) => {
          const Icon = item.fgComponent;

          return (
            <a
              className="for-newcomers__item"
              href={item.href}
              key={idx}
              style={{
                backgroundColor: `#${item.bgColor}`,
              }}
            >
              <img
                className="for-newcomers__item-bg-img"
                src={`.${item.bgImg}`}
              />
              <div className={`for-newcomers__item-fg-img for-newcomers__item-fg-img--${item.fgCards.length}`}>
                {item.fgCards.map((item, idx) => (
                  <img
                    className={`for-newcomers__item-fg-img-src for-newcomers__item-fg-img-src--${idx + 1}`}
                    src={item}
                  />
                ))}
              </div>
              <div className="for-newcomers__item-content">
                <div className="for-newcomers__item-header">
                  {item.header}
                </div>
                <div className="for-newcomers__item-description">
                  {item.description}
                </div>
                <div className="for-newcomers__item-from">
                  {formatNumber(item.from)} ₽
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default ForNewcomers;
