import React from 'react';

import { ReactComponent as LogoSvg } from './logo.svg';

import './Logo.css';

function Logo({
  className = ''
}) {
  return (
    <div
      className={`logo ${className}`}
    >
      <LogoSvg
        className="logo__icon"
      />
    </div>
  );
}

export default Logo;
