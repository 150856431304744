import React, { useState } from 'react';

import useInterval from 'hooks/useInterval';

import './Badge.css';

function Badge({
  className = '',

  small,

  values,
  children,
}) {
  const [currentAnimationIdx, setCurrentAnimationIdx] = useState(0);

  let previousAnimationIdx = -1;
  if (values && values.length > 1) {
    previousAnimationIdx = currentAnimationIdx === 0 ? values.length - 1 : currentAnimationIdx - 1;
  }

  useInterval(() => {
    if (values && values.length > 1) {
      if (currentAnimationIdx === (values.length - 1)) {
        setCurrentAnimationIdx(0);
      } else {
        setCurrentAnimationIdx(currentAnimationIdx + 1);
      }
    } else {
      setCurrentAnimationIdx(0);
    }
  }, 1500);

  return (
    <div
      className={`badge ${className}${small ? ' badge--small' : ''}`}
    >
      <div className="badge__wrap">
        {values ? (
          values.map((el, idx) => (
            <div
              className={`badge__item${currentAnimationIdx === idx ? ' badge__item--visible' : ''}${previousAnimationIdx === idx ? ' badge__item--previous' : ''}`}
            >
              {el}
            </div>
          ))
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default Badge;
