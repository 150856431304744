import React from 'react';

import './Useful.css';

function Useful({
  className = ''
}) {
  return (
    <div
      className={`useful ${className}`}
    >
      <h2 className="header useful__header">
        Полезное
      </h2>
      <div className="useful__content">
        <a
          className="useful__item useful__item--full-width"
          style={{
            backgroundColor: `#EFE3DA`,
          }}
          href="#"
        >
          <div className="useful__item-wrap">
            <img
              className="useful__item-img"
              src="./assets/useful/1.png"
            />
            <div className="useful__item-content">
              <div className="useful__item-header">
                Всё о лотереях
              </div>
              <div className="useful__item-description">
                Как устроены лотереи в России
              </div>
            </div>
          </div>
        </a>
        <div className="useful__row">
          <a
            className="useful__item useful__item--half-width"
            style={{
              backgroundColor: `#F2DCDD`,
            }}
            href="#"
          >
            <div className="useful__item-wrap">
              <img
                className="useful__item-img"
                src="./assets/useful/2.png"
              />
              <div className="useful__item-content">
                <div className="useful__item-header">
                  Мошенники
                </div>
                <div className="useful__item-description">
                  Не дай себя обмануть
                </div>
              </div>
            </div>
          </a>
          <a
            className="useful__item useful__item--half-width"
            style={{
              backgroundColor: `#E0E9D4`,
            }}
            href="#"
          >
            <div className="useful__item-wrap">
              <img
                className="useful__item-img"
                src="./assets/useful/3.png"
              />
              <div className="useful__item-content">
                <div className="useful__item-header">
                  Вещевые лотереи
                </div>
                <div className="useful__item-description">
                  История
                </div>
              </div>
            </div>
          </a>
        </div>
        <a
          className="useful__item useful__item--full-width"
          style={{
            backgroundColor: `#F0E7DD`,
          }}
          href="#"
        >
          <div className="useful__item-wrap">
            <img
              className="useful__item-img"
              src="./assets/useful/4.png"
            />
            <div className="useful__item-content">
              <div className="useful__item-header">
                Музей лотерей
              </div>
              <div className="useful__item-description">
                История лотерей от Петра I до наших дней
              </div>
            </div>
          </div>
        </a>
        <a
          className="useful__item useful__item--full-width"
          style={{
            backgroundColor: `#E0DFF0`,
          }}
          href="#"
        >
          <div className="useful__item-wrap">
            <img
              className="useful__item-img"
              src="./assets/useful/5.png"
            />
            <div className="useful__item-content">
              <div className="useful__item-header">
                Налог на выигрыш
              </div>
              <div className="useful__item-description">
                Сколько нужно заплатить
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Useful;
