import React from 'react';
import { DateTime } from 'luxon';

import Countdown from 'components/Countdown/Countdown';

import formatNumber from 'lib/formatNumber';

import { ReactComponent as SpecigraIcon } from './specigra.svg';

import './SpecialGames.css';

function SpecialGames({
  className = ''
}) {
  const currentTime = DateTime.local();
  const data = [
    {
      whenDraw: currentTime.plus({ hours: 72, minutes: 21, seconds: 12 }),
      header: 'iPhone 12 Pro',
      from: 50,
      img: '/assets/special/black-iphone.png',
      bgColor: '3767A8',
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 72, minutes: 21, seconds: 12 }),
      header: 'iPhone 12 Pro',
      from: 50,
      img: '/assets/special/gold-iphone.png',
      bgColor: '3767A8',
      href: '#',
    },
  ];

  return (
    <div
      className={`special-games ${className}`}
    >
      <h2 className="header special-games__header">
        Специгра
      </h2>
      <div className="special-games__content scroll-container scroll-container--big-padding">
        {data.map((item, idx) => (
          <a
            className="special-games__item"
            href={item.href}
            key={idx}
            style={{
              backgroundColor: `#${item.bgColor}`,
            }}
          >
            <img
              className="special-games__item-img"
              src={`.${item.img}`}
            />
            <div className="special-games__item-content">
              <SpecigraIcon
                className="special-games__item-icon"
              />
              <div className="special-games__item-sub-header">
                <Countdown whenTime={item.whenDraw} prefix="Через" />
              </div>
              <div className="special-games__item-header">
                {item.header}
              </div>
              <div className="special-games__item-button">
                Купить билет за {formatNumber(item.from)} ₽
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default SpecialGames;
