import React from 'react';
import { DateTime } from 'luxon';

import Countdown from 'components/Countdown/Countdown';

import formatNumber from 'lib/formatNumber';

import Badge from 'components/Badge/Badge';

import './InexpensiveGames.css';

function InexpensiveGames({
  className = ''
}) {
  const currentTime = DateTime.local();
  const data = [
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      subHeader: 'Тираж №1239',
      header: 'Угадайте 7 чисел из 49',
      img: '/assets/biglogos/7x49.svg',
      superPrize: 274313535,
      prizes: [
        30000,
        600,
        1000,
        15000,
      ],
      from: 25,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      subHeader: 'Тираж №1239',
      header: 'Выбери дату и знак зодиака',
      img: '/assets/biglogos/Zodiac.svg',
      superPrize: 274313535,
      prizes: [
        30000,
        600,
        1000,
        15000,
      ],
      from: 25,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      subHeader: 'Тираж №1239',
      header: 'Угадайте 6 чисел из 45',
      img: '/assets/biglogos/6x45.svg',
      superPrize: 274313535,
      prizes: [
        30000,
        600,
        1000,
        15000,
      ],
      from: 25,
      href: '#',
    },
  ];

  return (
    <div
      className={`inexpensive-games ${className}`}
    >
      <h2 className="header inexpensive-games__header">
        Недорогие билеты
      </h2>
      <div className="inexpensive-games__content scroll-container scroll-container--big-padding">
        {data.map((item, idx) => (
          <a
            className="inexpensive-games__item"
            href={item.href}
            key={idx}
          >
            <div className="inexpensive-games__item-sub-header">
              <Countdown whenTime={item.whenDraw} /> · {item.subHeader}
            </div>
            <div className="inexpensive-games__item-header">
              {item.header}
            </div>
            <div className="inexpensive-games__item-imgcontainer">
              <img
                className="inexpensive-games__item-img"
                src={`.${item.img}`}
              />
            </div>
            <div className="inexpensive-games__item-superprize">
              <div className="inexpensive-games__item-small-header">
                Суперприз
              </div>
              <Badge className="inexpensive-games__item-badge">
                {!isNaN(item.superPrize) ? `${formatNumber(item.superPrize)} ₽` : item.superPrize}
              </Badge>
            </div>
            <div className="inexpensive-games__item-prizes">
              <div className="inexpensive-games__item-small-header">
                Призы
              </div>
              <Badge
                className="inexpensive-games__item-badge"
                small
                values={item.prizes.map((el) => !isNaN(el) ? `${formatNumber(el)} ₽` : el)}
              />
            </div>
            <div className="inexpensive-games__item-from">
              от {formatNumber(item.from)} ₽
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default InexpensiveGames;
