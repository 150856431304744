import React, { useState } from 'react';

import HeaderMenu from './components/HeaderMenu/HeaderMenu';

import { ReactComponent as RepeatIcon } from './repeat.svg';
import { ReactComponent as UserIcon } from './user.svg';

import formatNumber from 'lib/formatNumber';

import './Header.css';

function Header({
  className = '',
  isUserAuthenticated,
}) {
  const [ balance, setBalance ] = useState(22240);

  function handleBalanceUpdate() {

  }

  return (
    <header
      className={`page-header ${className}`}
    >
      <HeaderMenu />
      <div className="page-header__right">
        {isUserAuthenticated ? (
          <div className="page-header__balance">
            {balance ? (
              <>
                <div className="page-header__balance-number">
                  {formatNumber(balance)}&nbsp;₽
                </div>
                <div className="page-header__balance-divider"></div>
              </>
            ) : null}
            <button
              className="page-header__balance-update"
              onClick={handleBalanceUpdate}
            >
              <RepeatIcon
                className="page-header__balance-update-icon"
              />
            </button>
          </div>
        ) : (
          <a
            className="page-header__user"
            href="#"
          >
            <UserIcon
              className="page-header__user-icon"
            />
          </a>
        )}
      </div>
    </header>
  );
}

export default Header;
