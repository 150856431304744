import React from 'react';

import './FixedCart.css';

function FixedCart({
  className = ''
}) {
  const multiply = 2;
  const price = 200;

  return (
    <div
      className={`fixed-cart ${className}`}
    >
      <div className="fixed-cart__left">
        x{multiply}
      </div>
      <div className="fixed-cart__right">
        {price} ₽
      </div>
    </div>
  );
}

export default FixedCart;
