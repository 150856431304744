import React from 'react';

import './Stories.css';

function Stories({
  className = ''
}) {
  const data = [
    {
      header: '',
      img: '/assets/stories/1.png',
      bgColor: '12061E',
      href: '#',
    },
    {
      header: 'Как проверить билет',
      img: '/assets/stories/2.png',
      bgColor: 'F4CBAD',
      href: '#',
    },
    {
      header: 'Новая экспозиция в музее',
      img: '/assets/stories/3.png',
      bgColor: 'E3DFD8',
      href: '#',
    },
    {
      header: 'Новогодние тиражи',
      img: '/assets/stories/4.png',
      bgColor: 'DFD0EE',
      href: '#',
    },
  ];

  return (
    <div
      className={`stories ${className}`}
    >
      <div className="stories__content scroll-container">
        {data.map((item, idx) => (
          <a
            className="stories__item"
            href={item.href}
            key={idx}
            style={{
              backgroundColor: `#${item.bgColor}`,
            }}
          >
            <img
              className="stories__item-img"
              src={`.${item.img}`}
            />
            {item.header ? (
              <div className="stories__item-header">
                {item.header}
              </div>
            ) : null}
          </a>
        ))}
      </div>
    </div>
  );
}

export default Stories;
