import React from 'react';

import './Footer.css';

function Footer({
  className = ''
}) {
  return (
    <footer
      className={`footer ${className}`}
    >
      <div className="footer__notice">
        Сумма суперпризов и джекпотов отображается без копеек
      </div>
      <a className="footer__button" href="https://www.stoloto.ru/mobile-applications">
        Мобильное приложение
      </a>
      <div className="footer__phone">
        <div className="footer__socials footer__phone-socials">
          <div className="footer__socials-list">
            <a className="footer__socials-item --telegram" href="https://t.me/stoloto_bot">
              <img className="footer__socials-item-img" src="./assets/footer/telegram.svg" />
            </a>
            <a className="footer__socials-item --viber" href="viber://pa/info?uri=stoloto_help">
              <img className="footer__socials-item-img" src="./assets/footer/viber.svg" />
            </a>
          </div>
        </div>
        <a className="footer__phone-number" href="tel:+79005550055">
          8 900 555-00-55
        </a>
        <div className="footer__phone-title footer__title">
          Поддержка
        </div>
      </div>
      <div className="footer__socials">
        <div className="footer__socials-list footer__socials-list--small">
          <a className="footer__socials-item --vk" href="http://vk.com/stoloto">
            <img className="footer__socials-item-img" src="./assets/footer/vk.svg" />
          </a>
          <a className="footer__socials-item --facebook" href="http://www.facebook.com/stoloto.ru">
            <img className="footer__socials-item-img" src="./assets/footer/facebook.svg" />
          </a>
          <a className="footer__socials-item --ok" href="http://ok.ru/stoloto">
            <img className="footer__socials-item-img" src="./assets/footer/ok.svg" />
          </a>
          <a className="footer__socials-item --instagram" href="http://instagram.com/stoloto/">
            <img className="footer__socials-item-img" src="./assets/footer/instagram.svg" />
          </a>
          <a className="footer__socials-item --youtube" href="https://www.youtube.com/c/stolototv?sub_confirmation=1">
            <img className="footer__socials-item-img" src="./assets/footer/youtube.svg" />
          </a>
          <a className="footer__socials-item --twitter" href="https://twitter.com/stoloto">
            <img className="footer__socials-item-img" src="./assets/footer/twitter.svg" />
          </a>
          <a className="footer__socials-item --telegram" href="https://t.me/aboutstoloto">
            <img className="footer__socials-item-img" src="./assets/footer/telegram.svg" />
          </a>
        </div>
        <div className="footer__socials-title footer__title">
          Соцсети
        </div>
      </div>
      <div className="footer__payment">
        <img className="footer__payment-img" src="./assets/footer/payment.svg" />
        <div className="footer__payment-title footer__title">
          Способы оплаты
        </div>
      </div>
      <div className="footer__secure">
        <div className="footer__secure-text">
          Мы гарантируем безопасность всех способов оплаты и не сохраняем ваши данные
        </div>
        <a className="footer__secure-link" href="https://compliance-control.ru/cert/stoloto/pcidss_compliance.html">
          <img className="footer__secure-img" src="./assets/footer/certified.svg" />
        </a>
      </div>
      <div className="footer__links">
        <div>
          <a className="footer__links-item" href="">
            Основная версия сайта
          </a>
        </div>
        <div>
          <a className="footer__links-item" href="">
            Правила программы лояльности
          </a>
        </div>
        <div>
          <a className="footer__links-item" href="">
            Политика в отношении обработки персональных данных
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
