import React from 'react';

import './LastGames.css';

function LastGames({
  className = ''
}) {
  const data = [
    {
      img: '/assets/logos/Top3.svg',
      href: '#',
    },
    {
      img: '/assets/logos/5x30.svg',
      href: '#',
    },
    {
      img: '/assets/logos/12x24.svg',
      href: '#',
    },
    {
      img: '/assets/logos/JL.svg',
      href: '#',
    },
    {
      img: '/assets/logos/Rapido.svg',
      href: '#',
    },
    {
      img: '/assets/logos/Top3.svg',
      href: '#',
    },
    {
      img: '/assets/logos/5x30.svg',
      href: '#',
    },
  ];

  return (
    <div
      className={`last-games ${className}`}
    >
      <h2 className="minor-header">
        Последние игры
      </h2>
      <div className="last-games__content scroll-container">
        {data.map((item, idx) => (
          <a
            className="last-games__item"
            href={item.href}
            key={idx}
          >
            <img
              className="last-games__item-img"
              src={`.${item.img}`}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default LastGames;
