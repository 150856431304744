import React from 'react';

import formatNumber from 'lib/formatNumber';

import { ReactComponent as WalletIcon } from './wallet.svg';
import { ReactComponent as ManIcon } from './man.svg';

import './Stats.css';

function Stats({
  className = ''
}) {
  const data = {
    win: 5000000000,
    winners: 20,
  };


  return (
    <div
      className={`stats ${className}`}
    >
      <div className="stats__col">
        <WalletIcon
          className="stats__icon"
        />
        <div className="stats__header">
          <nobr>{formatNumber(data.win)} ₽</nobr>
        </div>
        <div className="stats__description">
          уже выиграли<br />
          в 2021 году
        </div>
      </div>
      <div className="stats__col">
        <ManIcon
          className="stats__icon"
        />
        <div className="stats__header">
          <nobr>>{formatNumber(data.winners)}</nobr>
        </div>
        <div className="stats__description">
          лотерейных миллионеров каждую неделю
        </div>
      </div>
    </div>
  );
}

export default Stats;
