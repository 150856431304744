import React, { useState } from 'react';

import Header from 'components/Header/Header';

import Logo from 'components/Logo/Logo';
import UserTickets from 'components/UserTickets/UserTickets';
import LastGames from 'components/LastGames/LastGames';

import Stories from 'components/Stories/Stories';
import Stats from 'components/Stats/Stats';
import Relevant from 'components/Relevant/Relevant';
import FrequentDraws from 'components/FrequentDraws/FrequentDraws';
import SpecialGames from 'components/SpecialGames/SpecialGames';
import InexpensiveGames from 'components/InexpensiveGames/InexpensiveGames';
import ForNewcomers from 'components/ForNewcomers/ForNewcomers';
import Interesting from 'components/Interesting/Interesting';
import Useful from 'components/Useful/Useful';
import AllLotteries from 'components/AllLotteries/AllLotteries';

import Footer from 'components/Footer/Footer';

import FixedCart from 'components/FixedCart/FixedCart';

import getParameterByName from 'lib/getParameterByName';

import './App.css';

function App() {
  const [ isUserAuthenticated, setIsUserAuthenticated ] = useState(!getParameterByName('unauth'));
  const [ isCartActive, setIsCartActive ] = useState(!getParameterByName('nocart'));

  return (
    <div className={`main ${isUserAuthenticated ? 'main--authenticated' : 'main--unauthenticated'}`}>
      <Header
        isUserAuthenticated={isUserAuthenticated}
      />
      <div className="main__background-content">
        <div className="main__background-content-wrap">
          <Logo />
          {isUserAuthenticated ? (
            <>
              <UserTickets />
              <LastGames />
            </>
          ) : null}
        </div>
      </div>
      <div className="main__foreground-content">
        <Stories />
        <Stats />
        <Relevant />
        <FrequentDraws />
        <SpecialGames />
        <InexpensiveGames />
        <ForNewcomers />
        <Interesting />
        <Useful />
        <AllLotteries />
        <Footer />
      </div>
      {isCartActive ? (
        <FixedCart />
      ) : null}
    </div>
  );
}

export default App;
