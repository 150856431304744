import React from 'react';
import { DateTime } from 'luxon';

import UserTicketItem from './components/UserTicketItem/UserTicketItem';

import './UserTickets.css';

function UserTickets({
  className = ''
}) {
  const currentTime = DateTime.local();
  const data = [
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 1, seconds: 22 }),
      number: 2,
      lottery: '/assets/logos/JL.svg',
      bgColor: 'B3DBEE',
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 2, seconds: 31 }),
      number: 9,
      lottery: '/assets/logos/4x20.svg',
      bgColor: 'F3CFB3',
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 3, minutes: 22 }),
      number: 1,
      lottery: '/assets/logos/7x49.svg',
      bgColor: 'E2BEF3',
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 24, minutes: 24 }),
      number: 23,
      lottery: '/assets/logos/4x20.svg',
      bgColor: 'F3CFB3',
      href: '#',
    },
  ];

  return (
    <div
      className={`user-tickets ${className}`}
    >
      <h2 className="minor-header">
        Мои билеты
      </h2>
      <div className="user-tickets__content scroll-container">
        {data.map((item, idx) => (
          <div
            className="user-tickets__item"
            key={idx}
          >
            <UserTicketItem
              ticket={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserTickets;
