import React from 'react';
import { DateTime } from 'luxon';

import Badge from 'components/Badge/Badge';
import Countdown from 'components/Countdown/Countdown';

import formatNumber from 'lib/formatNumber';

import './FrequentDraws.css';

function FrequentDraws({
  className = ''
}) {
  const currentTime = DateTime.local();
  const data = [
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      header: 'Угадай числа в двух полях',
      img: '/assets/biglogos/Rapido.svg',
      superPrize: 274313535,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 60,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      header: 'Угадайте 7 чисел из 49',
      img: '/assets/biglogos/7x49.svg',
      superPrize: 274313535,
      prizes: [
        300,
        600,
        1000,
        15000,
      ],
      from: 25,
      href: '#',
    },
    {
      whenDraw: currentTime.plus({ hours: 0, minutes: 21, seconds: 12 }),
      header: 'Угадай 1, 2 или 3 числа',
      img: '/assets/biglogos/Top3.svg',
      superPrize: 274313535,
      prizes: [
        15000,
        300,
        600,
        1000,
      ],
      from: 60,
      href: '#',
    },
  ];

  return (
    <div
      className={`frequent-draws ${className}`}
    >
      <h2 className="header frequent-draws__header">
        Частые розыгрыши
      </h2>
      <div className="frequent-draws__content">
        {data.map((item, idx) => (
          <a
            className="frequent-draws__item"
            href={item.href}
            key={idx}
          >
            <img
              className="frequent-draws__item-img"
              src={`.${item.img}`}
            />
            <div className="frequent-draws__item-sub-header">
              <Countdown whenTime={item.whenDraw} />
            </div>
            {item.header ? (
              <div className="frequent-draws__item-header">
                {item.header}
              </div>
            ) : null}
            <div className="frequent-draws__item-bottom">
              <div className="frequent-draws__item-superprize">
                <div className="frequent-draws__item-small-header">
                  Суперприз
                </div>
                <Badge className="frequent-draws__item-badge">
                  {!isNaN(item.superPrize) ? `${formatNumber(item.superPrize)} ₽` : item.superPrize}
                </Badge>
              </div>
              <div className="frequent-draws__item-prizes">
                <div className="frequent-draws__item-small-header">
                  Призы
                </div>
                <Badge
                  className="frequent-draws__item-badge"
                  small
                  values={item.prizes.map((el) => !isNaN(el) ? `${formatNumber(el)} ₽` : el)}
                />
              </div>
            </div>
            <div className="frequent-draws__item-from">
              от {formatNumber(item.from)} ₽
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default FrequentDraws;
