export default function getParameterByName(argName, argUrl) {
  let name = argName;
  let url = argUrl;

  if (!url) {
    url = window.location.href;
  }

  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
