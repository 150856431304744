import React, { useState, useEffect } from 'react';

import { ReactComponent as MenuIcon } from './menu.svg';
import { ReactComponent as BackIcon } from './back.svg';

import './HeaderMenu.css';

function HeaderMenu({
  className = '',
}) {
  const [ menuShown, setMenuShown ] = useState(false);

  function handleButton() {
    setMenuShown(!menuShown);
  }

  useEffect(() => {

    if (menuShown) {
      document.body.classList.add('body--fix-overfow');
    } else {
      document.body.classList.remove('body--fix-overfow');
    }

    return () => {
      document.body.classList.remove('body--fix-overfow');
    };
  }, [menuShown])

  return (
    <>
      <button
        className="page-header__menu-button"
        onClick={handleButton}
      >
        <MenuIcon
          className="page-header__menu-button-icon"
        />
      </button>
      <div
        className={`header-menu${menuShown ? ' header-menu--visible' : ''} ${className}`}
      >
        <div className="header-menu__nav">
          <button
            className="header-menu__menu-button"
            onClick={handleButton}
          >
            <BackIcon
              className="header-menu__menu-button-icon"
            />
          </button>
        </div>
        <div className="header-menu__list">
          <a className="header-menu__item header-menu__item--all" href="https://m.stoloto.ru/">
            Все лотереи
          </a>

          <div className="header-menu__divider"></div>

          <a className="header-menu__item header-menu__item--profile" href="https://m.stoloto.ru/login">
            Вход / Регистрация
          </a>

          <div className="header-menu__divider"></div>

          <a className="header-menu__item header-menu__item--live" href="https://m.stoloto.ru/live?int=sitemap">
            Трансляции
          </a>
          <a className="header-menu__item header-menu__item--check" href="https://m.stoloto.ru/check?int=sitemap">
            Проверка билетов
          </a>
          <a className="header-menu__item header-menu__item--archive" href="https://m.stoloto.ru/archive?int=sitemap">
            Архив тиражей
          </a>

          <div className="header-menu__divider"></div>

          <a className="header-menu__item header-menu__item--where-new" href="https://m.stoloto.ru/map?int=sitemap">
            Лотереи в городе
          </a>
          <a className="header-menu__item header-menu__item--map" href="https://m.stoloto.ru/faq?int=sitemap">
            Вопрос-Ответ
          </a>
          <a className="header-menu__item header-menu__item--sms" href="https://m.stoloto.ru/sms-portal?int=sitemap">
            Билет в СМС
          </a>
          <a className="header-menu__item header-menu__item--news" href="https://m.stoloto.ru/news?int=sitemap">
            Новости
          </a>
          <a className="header-menu__item header-menu__item--company" href="https://m.stoloto.ru/about?int=sitemap">
            О компании
          </a>
          <a className="header-menu__item header-menu__item--contacts" href="https://m.stoloto.ru/contacts?int=sitemap">
            Контакты
          </a>
        </div>
      </div>
    </>
  );
}

export default HeaderMenu;
