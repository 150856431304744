import React from 'react';

import Countdown from 'components/Countdown/Countdown';

import './UserTicketItem.css';

function UserTicketItem({
  className = '',

  ticket,
}) {
  return (
    <a
      className={`user-ticket-item ${className}`}
      style={{
        backgroundColor: `#${ticket.bgColor}`,
      }}
      href={ticket.href}
    >
      <div className="user-ticket-item__content">
        <div className="user-ticket-item__time">
          <Countdown whenTime={ticket.whenDraw} />
        </div>
        <div
          className={`user-ticket-item__number${ticket.number >= 10 ? ' --more-than-ten' : ''}`}
        >
          {ticket.number}
        </div>
      </div>
      <img
        className="user-ticket-item__logo"
        src={`.${ticket.lottery}`}
      />
    </a>
  );
}

export default UserTicketItem;
