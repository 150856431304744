import React, { useState, useEffect } from 'react';

import { DateTime } from 'luxon';

import declOfNum from 'lib/declOfNum';
import useInterval from 'hooks/useInterval';

// import './Example.css';

function Countdown({
  whenTime,
  prefix = '',
}) {
  const [ updateTime, setUpdateTime ] = useState(Date.now());

  let timePhrase = '';

  const currentTime = DateTime.local();
  const time = DateTime.fromISO(whenTime);

  const timeDiff = time.diff(currentTime, ['hours', 'minutes', 'seconds']);
  const timeDiffObj = timeDiff.toObject();

  if (timeDiffObj.seconds < 0) {
    timePhrase = '--:--';
  // less than 3 hours
  } else if (timeDiffObj.hours < 1) {
    timePhrase += `${prefix} `;
    timePhrase += timeDiff.toFormat('mm:ss');
  // less than 24 hours
  } else if (timeDiffObj.hours >= 24) {
    const day = timeDiff.toFormat('d');

    timePhrase += `${prefix} `;
    timePhrase += `${day} ${declOfNum(day, ['день', 'дня', 'дней'])}`;
  } else {
    const hour = timeDiff.toFormat('h');

    timePhrase += `${prefix} `;
    timePhrase += `${hour} ${declOfNum(hour, ['час', 'часа', 'часов'])}`;
  }

  useInterval(() => {
    setUpdateTime(Date.now());
  }, 1000);

  return (
    <>
      {timePhrase}
    </>
  );
}

export default Countdown;
